<template>
  <div class="site-description">
    <article
      :class="{ 'site-description__container--full': !marketplace.head_img }"
      class="site-description__container"
    >
      <ImgComponent
        class="site-description__image"
        :width="416"
        :head_img="marketplace.head_img"
        v-if="marketplace.head_img"
      />
      <div class="site-description__article">
        <h1 class="site-description__title">{{ marketplace.title }}</h1>
        <EditorJSComponent
          class="site-description__text"
          :class="{ 'site-description__text--expanded': expand }"
          ref="description"
          v-if="marketplace.description"
          :text="marketplace.description"
        />
        <button @click="expandToggle" class="site-description__expand btn btn--sm btn--main-inv">
          <span v-if="expand">Скрыть</span>
          <span v-else>Показать все</span>
        </button>
      </div>
    </article>
  </div>
</template>

<script>
import EditorJSComponent from "components/EditorJSComponent.vue";
import { elementInViewport } from "@/utils";

export default {
  name: "SiteDescriptionComponent",
  props: {
    marketplace: Object,
  },
  data() {
    return {
      expand: false,
    };
  },
  methods: {
    expandToggle() {
      this.expand = !this.expand;
      const el = this.$refs.description.$el;
      if (this.expand) {
        el.style.maxHeight = el.scrollHeight + "px";
      } else {
        el.style.maxHeight = "300px";
        this.scrollIntoView(el);
      }
    },
    scrollIntoView(el) {
      setTimeout(() => {
        if (!elementInViewport(el)) {
          el.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
        }
      }, 100);
    },
  },
  components: { EditorJSComponent },
};
</script>

<style lang="stylus">
.site-description {
  display flex
  justify-content center
  align-items center
  padding 0 15px

  &__container {
    max-width var(--small_width)
    width 100%
    display grid
    align-items flex-start
    grid-gap 20px
    grid-template-columns 640fr 610fr
    +below(1024px) {
      grid-template-columns 5fr 5fr
    }
    +below(860px) {
      grid-template-columns 1fr
    }

    &--full {
      grid-template-columns 1fr
    }
  }

  &__image {
    object-fit cover
    object-position center
		width: 100%
		height: 100%
    max-height 430px
    margin 0 auto
    border-radius: var(--main_radius);
		+above(861px) {
			position: sticky
			top: 100px
		}
  }

  &__article {
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    white-space pre-line

    p {
      font-weight: normal;
      font-size: 0.875rem;
			line-height: 24px; /* 171.429% */
      display: flex;
      align-items: center;
      color: var(--body-color-muted);
    }
  }

  &__title {
    font-size: 1.5rem;
    line-height: 133.333%
    display: flex;
    align-items: center;
    color: var(--gray-900);
  }

	&__text {
		position: relative
		max-height: 300px
		overflow hidden
		transition var(--transition)

		&:not(&--expanded) {
			&:after {
				content ""
				display block
				width 100%
				height 120px
				position absolute
				bottom 0
				background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, var(--white) 100%)
				pointer-events: none
			}
		}
	}

	&__expand {
		margin-top 10px
		width 100%
	}
}
</style>
